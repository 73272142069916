.footer {
  background-color: #000;
}

.icon-footer-social {
  height: 30px;
  transition: all 0.3s ease-in-out;
  width: auto;
  &:hover {
    filter: brightness(0) saturate(100%) invert(69%) sepia(61%) saturate(739%)
      hue-rotate(4deg) brightness(87%) contrast(96%);
  }
}

.icon-footer {
  height: 5rem;
}

.box-sydea-logo {
    width: 150px;
}

.cls-1-sydea,
.cls-3-sydea {
  fill: #f6f6f6;
}

.cls-2-sydea {
  fill: #fdc924;
}

.mail-info-footer{
  text-decoration: none;
  transition: all .3s ease-in-out;
  &:hover{
    color: var(--RedWine);
  }
}

#nav-footer{
  & ul {
    list-style: none;
  }
  & a{
    transition: all .3s ease-in-out;
    color: #fff;
    text-decoration: none;
    &:hover{
      color: var(--GoldHoney);
    }
  }
  
}