.cls-1-trs {
  fill: #f7f7f7;
}

.cls-2-trs {
  fill: #edc500;
}

.cls-1-syd {
  fill: #f6f6f6;
}
.cls-2-syd {
  fill: #feca24;
}

.logo-about{
  height: 50px;
  max-width: 100%;
}

.txt-about{
  & a{
    text-decoration: none;
    color: inherit;
    transition: all .3s ease-in-out;
    &:hover{
      color: var(--RedWine);
    }
  }
  & span.yc-gold-honey{
    transition: all .3s ease-in-out;
    &:hover{
      color: var(--RedWine);
    }
  }
}