.bg-about {
  // background: url("../../assets/pattern_icon.svg");
  // background-position: 50% 50%;
  // background-size: cover;
  // height: 100%;
  // width: 100%;
  // display: flex;
  // align-items: center;
  // position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../assets/pattern_icon.svg");
    background-position: 50% 50%;
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    z-index: -1;
  }
}

.box-about {
  background-color: var(--GoldHoney);
}

.img-stores {
  height: 5rem;
}

.label-about {
  color: #050505;
  font-size: 1.5rem;
}

.bg-nft-yawks {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/image/hero-yawks.png");
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0.35;
    z-index: -1;
  }
}

.bg-nft-yawks-img {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.title-yc {
  font-size: 5rem;
}

.subtitle-yc {
  font-size: 2.5rem;
}

.hero-image {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.bg-sec-news {
  background: url("../../assets/image/ph-yawclub.png");
  background-position: left bottom;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.layer-intro {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9991;
  background-color: #000;
  transition: all 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hidden {
    opacity: 0;
    z-index: -1;
  }
}

.box-loader-intro{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.logo-loader-intro{
  height: 100px;
}