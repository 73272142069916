.logo-the-yawks {
  height: 100px;
}

.img-yawk {
  height: 200px;
}

.box-logo-mp {
  background-color: #121212;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--GoldMoney);
    transform: scale(1.05);
  }
}

.logo-mp {
  height: 50px;
  max-width: 100%;
}

.box-desc-yawks{
  background-color: #000000ad;
}

.btn-more-info-yawks{
  border: 1px solid var(--GoldMoney);
  text-decoration: none;
  color: var(--GoldMoney);
  transition: all 0.3s ease-in-out;
  &:hover{
    background-color: var(--GoldMoney);
    color: #fff;
  }
}