.box-info-game {
  background-color: #121212;
  transition: all 0.3s ease-in-out;
  align-items: flex-start;
  height: 100%;
  &:hover {
    color: var(--YawRed);
    transform: scale(1.05);
  }
}

.icon-game {
  height: 60px;
}

.sub-box-game {
  color: #fff;
}

.cls-1 {
  fill: #cea709;
  transition: all 0.3s ease-in-out;
}
