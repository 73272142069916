#yc-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 3rem;
  
    & li {
      list-style-type: none;
      position: static;
      & .activeNav {
        color: var(--GoldHoney);
      }
    }
    & a {
      color: #f5f5f5;
      text-decoration: none;
      transition: 0.3s ease-in-out;
      font-size: 1.5rem;
      &:hover {
        color: var(--GoldHoney);
      }
    }
  }


.logo-nav {
  width: 60px;
  height: auto;
}

.navbar{
    background-color: #000000b5;
}