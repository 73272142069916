@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
  color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --YawRed: #d21317;
  --RedWine: #ab1926;
  --GoldHoney: #cea705;
  --GoldMoney: #ba9806;
}

.section-home{
  min-height: 100vh;
  padding-top: 5rem;
}

.yc-gold-honey{
  color: var(--GoldHoney);
}

.yc-red{
  color: var(--YawRed);
}



